import LocationBanner from "components/locationBanner";
import React from "react";
import Birthcomp from "./Birthcomp";
import HighQuality from "./HighQuality";
import ChooseTom from "./ChooseTom";
import { BirthCertificationData, ChooseTomData, SliderData } from "./config";
import SpeceficTranslation from "components/LocationReusableComp/speceficTranslation";
import TechnicalDocument from "../technicalDocument";
import VariousTranslation from "../VariousTranslation";
import FAQ from "./FAQs";
import WhatsAppComp from "../whatsappComp";
import WhatChooseUs from "components/homepage/whyChooseUs";
import Reviews from "components/homepage/reviews";
import RecentBirthComp from "./RecentBirthComp";

function BirthCertificateTranslation() {
  return (
    <>
      <LocationBanner
        backgroundCol="bg-midBlue"
        title="Birth Certificate Translation Services"
        content="Birth Certificate Translation is a way for you to broaden horizons to the rest of the world. Why? Because translating your birth certificate will grant you access to your aspirations abroad, in whatever institution you're applying for. Certified birth certification translation and USCIS birth certificate translation are your gateway to your future."
        formOrangeHead="Translate"
        formWhiteHead="your birth certificate now"
      />
      <Birthcomp />
      <HighQuality />
      <ChooseTom ChooseTomData={ChooseTomData} />
      <SpeceficTranslation
        FirstOrange=" "
        FirstWhite="Translate Your"
        ColorHead="Birth Certificates"
        SecondWhite="into 120 Languages and over 950 Language Pairs"
        headColor="text-orange"
        linkName=" "
        Content="Tomedes manages a global team of linguists with expertise in translating more than 950+ language pairs. Although birth certificates generally contain similar information in all localities, there are subtleties and nuances in the formulation of each within each nation. Translators must be familiar with the requirements of each location and translate birth certifications precisely according to the standards of that jurisdiction. Tomedes hand-picks a birth certificate translator familiar with both the language of your birth certificate and the language of the required translation."
        backgroundCol="bg-midBlue"
        getStarted
      />
      <TechnicalDocument
        firstHead=" "
        colHead="Send us "
        secondHead="your Birth Certificate"
        para="To get a quick and accurate estimate on your work, please send an image file of your birth certificate and language and place of the translation required. We will respond promptly."
      />
      <VariousTranslation
        backgroundCol="bg-midBlue"
        ftitle=" "
        para="Do you have a dream use that you aspire to when it comes to your birth certificate translation? Usually, these translations are part of larger processes of applications. Make sure youâ€™re ready for these types of use cases:"
        title="Common Uses for Translated Birth Certificates"
        data={BirthCertificationData}
      />
      <FAQ SliderData={SliderData} />
      <WhatsAppComp
        backgroundCol="bg-midBlue"
        fHeadCol="text-orange"
        fHead="Translating"
        secondHead="birth certificates has never been this easy"
        whatsUp
      />
      <RecentBirthComp />
      <WhatChooseUs backgroundCol="bg-midBlue" />
      <Reviews backgroundCol="bg-darkBlue" />
    </>
  );
}

export default BirthCertificateTranslation;
