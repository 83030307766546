import Layout from 'components/layout'
import BirthCertificateTranslation from 'components/reusableComponents/BirthCertificate/BirthCertificateTranslation'
import SEO from 'components/seo'
import React from 'react'

function index() {
    const title = "Birth Certificate Translation Services | Tomedes";
    const description = "Tomedes provides birth certificate translation services for individuals and companies, certified to meet the standards of USCIS and governments worldwide." ;
    const keywords = "birth certificate translation, professional birth certificate translation, birth certificate translation quote";
    return (
        <Layout>
            <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/birth-certificate-translation.php"
      />
            <BirthCertificateTranslation/>
        </Layout>
    )
}

export default index
