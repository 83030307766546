import React from "react";
import Spanish from "assets/spa_eng-1.svg";
import Por from "assets/por_eng.svg";
import Spa from "assets/spa_eng.svg";
import Ita from "assets/ita_eng.svg";

export default function RecentBirthComp() {
  return (
    <div className="bg-darkBlue py-16">
      <div className="flex md:flex-row flex-col w-full lg:w-10/12 font-secondary justify-between mx-auto item-start px-5">
        <div className="left w-full lg:w-[40%]">
          <h2 className="text-[#e6e6e6] leading-relaxed text-[35px]  font-semibold">
            Our recent birth certificate translations
          </h2>
          <p className="text-[#BEBEBE] leading-loose font-sans pt-6">
            Performing birth certificate translation is a core service that
            Tomedes provides to individuals, law firms, and corporations. As
            vital records used in verifying identity, birth certificates and
            their translation are treated with the utmost seriousness and
            professional care.
          </p>
        </div>
        <div className="right text-[#e6e6e6] w-full lg:w-[40%] flex flex-col gap-y-16 lg:pt-0 pt-8">
          <div className="flag border-b border-[#e6e6e6] pb-4">
            <img src={Spanish} alt="" />
            <a href="https://www.tomedes.com/recent-translations/spanish-to-english-birth-certificate-translation.php" target="_blank">
              <h3 className="text-[22px]">
                {" "}
                <span className="text-lightBlue">Spanish to English</span> Birth
                Certificate Translation
              </h3>
            </a>
          </div>
          <div className="flag border-b border-[#e6e6e6] pb-4">
            <img src={Ita} alt="" />
            <a href=" https://www.tomedes.com/recent-translations/italian-to-english-birth-certificate-translation.php">
              <h3 className="text-[22px]">
                {" "}
                <span className="text-lightBlue">Italian to English</span> Birth
                Certificate Translation
              </h3>
            </a>
          </div>
          <div className="flag border-b border-[#e6e6e6] pb-4">
            <img src={Por} alt="" />
            <a href="https://www.tomedes.com/recent-translations/portuguese-to-english-birth-certificate-translatio.php" target="_blank">
              <h3 className="text-[22px]">
                {" "}
                <span className="text-lightBlue">
                  Portuguese to English
                </span>{" "}
                Birth Certificate Translation
              </h3>
            </a>
          </div>
          <div className="flag border-b border-[#e6e6e6] pb-4">
            <img src={Spa} alt="" />
            <h3 className="text-[22px]">
              <a href="https://www.tomedes.com/recent-translations/certified-spanish-english-certificate-translation.php" target="_blank">
                {" "}
                <span className="text-lightBlue">
                  Certified Spanish to English
                </span>{" "}
                Birth Certificate Translation
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
