export const ChooseTomData = [
  {
    Colheading: "Certified",
    heading: "",
    img_link: "",
    secondHeading: "Birth Certificate Translation",
    para: "Tomedes' certification process has been verified by the American Translator's Association, to provide the best certified translation services. You can be assured that the expert linguist hand-picked to translate your birth certificate is qualified through two ways: fluency in the languages, and qualified by qualifying tests to provide birth certificate translations.",
  },
  {
    Colheading: "1-Year",
    heading: "",
    img_link: "",
    secondHeading: "Guarantee",
    para: "Tomedes offers a 100% guarantee that its birth certificate translation will be accepted by any authority. Our seal is guaranteed to be accepted by private and public institutions worldwide. We are confident in the quality of our work. As such, we can guarantee the quality of your translated documents for a year after delivery.",
  },
  {
    Colheading: "USCIS",
    heading: "Made for the",
    img_link: "",
    secondHeading: "",
    para: "Translate your birth certificate into english for the USCIS. A certified translator who is qualified for USCIS translations will create your translations, and Tomedesâ€™ certified birth certificate translation will ensure that your translations are true to the original and acceptable by the USCIS. Your birth certificate translation for USCIS is prepared in precisely the form that USCIS requires.",
  },
  {
    Colheading: "Worldwide",
    heading: "Accepted by Private and Public Institutions",
    img_link: "",
    secondHeading: "",
    para: "Tomedes’ certified translation services are guaranteed to be accepted by private and public institutions worldwide. Each project involving documents for private and public institutions worldwide is assigned to a translator trained and certified to meet its requirements.",
  },
];

export const BirthCertificationData = [
  {
    title: "USCIS Application",
    detail:
      "With all immigration and migration processes, it takes hard work to go with your aspiration to migrate. Tomedes takes the work out of your equation, because we're verified to provide USCIS birth certificate translations. The United States Citizenship and Immigration Service (USCIS) requires English language documents as a condition for approving work, study and citizenship applications.",
  },
  {
    title: "University Applications",
    detail:
      "Your dream school is waiting for you, all you have to do is aspire with Tomedes. Whether it's for your first year of college, your study abroad, or your transfer to a university, your birth certificates need to be translated in the language of the school. Tomedes' official birth certificate translations are certified in order to provide you with acceptable admission to your dream school abroad.",
  },
  {
    title: "Corporate Onboarding",
    detail:
      "When you combine your corporate aspirations with Tomedes' birth certificate translation company, your dream job awaits you. Many corporations require translated birth certificates as part of their onboarding process. When you sign up for a corporate onboarding process, all your documents should be translated into the language of the company, including your birth certificate.",
  },
  {
    title: "Government Submissions",
    detail:
      "Submitting original documents for everything that you're applying for at government agencies such as the DMV, the Foreign Affairs Department, and all other departments takes huge leaps to make your aspirations come to life. Translating your birth certificate is one of those leaps, making your application understandable and error-free.",
  },
  {
    title: "Legal Purposes",
    detail:
      "Legal matters affect the person involved, so birth certificate translation for legal purposes must be quality translations. It's a good thing that Tomedes' birth certificate translations are notarized to meet the quality that law offices require. Translating a birth certificate is a translation of a legal life event, so it's good you have Tomedes translators handling the job.",
  },
];

export const SliderData = [
  {
    heading:
      "What are the important things to remember when translating your birth certificate?",
    para: "Birth certificate translations need to be certified by a trained and accredited translator signed and stamped according to the format required in your specific jurisdiction. Don't take the chance that your birth certificate submission will be rejected. Tomedes provides a free evaluation for certified birth certificate translation for your peace of mind.",
    id: "111",
  },
  {
    heading: "Where can I get my birth certificate translated?",
    para: "Birth certificate translations need to be certified by a trained and accredited translator signed and stamped according to the format required in your specific jurisdiction. Don't take the chance that your birth certificate submission will be rejected. Tomedes provides a free evaluation for certified birth certificate translation for your peace of mind.",
    id: "222",
  },
  {
    heading: "How can I find a birth certificate translator near me?",
    para: "Look no further! Tomedes birth certificate translation services are available around the clock and around the world. Just use the form above to send us an image of your birth certificate and information about your translation needs. We will respond rapidly with the information you need to know.",
    id: "333",
  },
  {
    heading: "Can I translate my birth certificate?",
    para: "Birth certificate translations are not a “do it yourself” affair. Because birth certificates are a primary vital record and proof of identity, authorities require that the translation of a birth certificate is performed by a trained and accredited translator, producing a signed and stamped certification to prove the translation accurate.",
    id: "444",
  },
  {
    heading: "How do you translate birth certificates?",
    para: "How we translate birth certificates largely depends on your request. We can accommodate birth certificate translations through using our regular, qualified translation service, while we can also accommodate certified birth certificate translations that are sealed with a proof of translation.",
    id: "555",
  },
];
