import React from 'react'

function ChooseTom({ChooseTomData}) {
    return (
        <div className="flex items-center justify-center bg-darkBlue py-12">
            <div className="flex items-center justify-center w-full px-3 2xl:6/12 xl:w-8/12 md:px-0 flex-col max-w-screen-xl">
             <div className="border-b border-orange w-full">
                 <h2 className="text-white mb-10 block md:leading-[52px] leading-8 text-center md:text-left text-3xl md:text-4xl font-secondary font-bold">Choosing Tomedes' Birth Certificate <br/>Translation Service</h2>
             </div>
             <div className="flex items-center justify-center">
             <div className="grid grid-cols-1 xl:grid-cols-2 w-full ">
                 {ChooseTomData ? ChooseTomData.map(({Colheading , heading ,secondHeading, img_link , para}) => (
                            <div className="flex items-center justify-center flex-col px-3 lg:pr-2 xl:pr-20 my-5 md:mt-8">
                            <img src={img_link} alt={img_link} className=" inline-block mr-auto my-4" />
                            <h3 className="font-secondary text-3xl font-bold text-white leading-10 mb-4 mr-auto">
                                {heading ? <span className="text-white ">{heading}</span> : null }{" "}<br/>
                                {Colheading ? <span className="text-orange ">{Colheading}</span> : null}{" "}<br/>
                                {secondHeading ? <span className="text-white ">{secondHeading}</span> : null}
                            </h3>
                            <p className="inline-block mr-auto leading-8 text-darkMist font-opensans pr-0 md:pr-20">{para}</p>
                            </div>
                 )) : null}
                
              
             </div>
             </div>
            </div>
        </div>
    )
}

export default ChooseTom
